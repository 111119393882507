'use client';
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/popover';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import slugify from 'react-slugify';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import LinkCustom from '@/app/_components/link/LinkCustom';
import { ReactComponent as ArrowBackwardCircleDark } from '@/app/_icons/ArrowBackwardCircleDark.svg';
import { ReactComponent as ArrowBackwardCircleOutline } from '@/app/_icons/ArrowBackwardCircleOutline.svg';
import { ReactComponent as ArrowForwardCircleDark } from '@/app/_icons/ArrowForwardCircleDark.svg';
import { ReactComponent as ArrowForwardCircleOutline } from '@/app/_icons/ArrowForwardCircleOutline.svg';
import { ReactComponent as ArroRightBlack } from '@/app/_icons/CkChevronRight.svg';
import { ReactComponent as CkGreyLeftArrow } from '@/app/_icons/CkGreyLeftArrow.svg';
import { ReactComponent as CkGreyRightArrow } from '@/app/_icons/CkGreyRightArrow.svg';
import { getImageLink } from '@/services/link.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { BodyTypesGrouping } from '@/types/home/home.types';
import { NewCarOverview } from '@/types/new-cars/new-cars.types';
import { directusImageLoader } from '@/utils/loaders';

import { PriceFormat } from '../price-format/price-format';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './FancyCarousel.scss';

type Props = {
  imageHrefBase: string;
  backgroundImageKeys: ImageTransformationKeys;
  imageKeys: ImageTransformationKeys;
  body_types: BodyTypesGrouping[];
  new_cars_background: string | null;
  carsWithOverview?: NewCarOverview[];
  tabMode?: boolean;
  isDealershipPage?: boolean;
  sectionTitle?: string;
};

const currentYear = new Date().getFullYear();
const FancyCarousel = ({
  imageHrefBase,
  backgroundImageKeys,
  imageKeys,
  body_types,
  new_cars_background,
  carsWithOverview,
  tabMode,
  isDealershipPage,
  sectionTitle = 'Discover your new Kia',
}: Props) => {
  const swiperRef: RefObject<SwiperRef> = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentCars, setCurrentCars] = useState<NewCarOverview[]>(
    carsWithOverview || [],
  );
  const [currentSelectedBodyType, setCurrentSelectedBodyType] = useState<
    string[]
  >([]);
  const [currentCarDetail, setCurrentCarDetail] = useState<NewCarOverview>();

  useEffect(() => {
    const currentFilteredCars =
      carsWithOverview?.filter((car) => {
        if (currentSelectedBodyType.length === 0) {
          return true;
        }
        return currentSelectedBodyType.includes(car.body_type.value);
      }) || [];

    swiperRef.current?.swiper?.updateSlides();
    setCurrentCars([...currentFilteredCars]);
    setTotalPages(currentFilteredCars?.length || 0);
  }, [currentSelectedBodyType, carsWithOverview]);

  useEffect(() => {
    const currentCar = currentCars?.[currentPage - 1];

    if (currentCar) {
      setCurrentCarDetail(currentCar);
    }
  }, [currentCars, currentPage]);

  const handleBodyTypeSelection = (selectedBodyTypes: string[]) => {
    setCurrentSelectedBodyType(selectedBodyTypes);
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const getAvailableBodyTypes = useMemo(() => {
    const availableTypes = new Set<string>();

    (carsWithOverview || []).forEach((car: NewCarOverview) => {
      availableTypes.add(car.body_type.value);
    });
    return Array.from(availableTypes);
  }, [carsWithOverview]);

  const filteredBodyTypes = useMemo(
    () =>
      body_types.filter(
        (grouping) =>
          grouping.label === 'All' ||
          grouping.body_types.some((type) =>
            getAvailableBodyTypes.includes(type),
          ),
      ),
    [body_types, getAvailableBodyTypes],
  );

  const CustomNavigation = () => {
    return (
      <div className="swiper-navigation">
        <button
          onClick={goPrev}
          className="swiper-button-prev-2"
          role="button"
          name="Prev"
        >
          <Show breakpoint="(min-width: 768px)">
            <CkGreyLeftArrow />
          </Show>
          <Show breakpoint="(max-width: 767px)">
            {tabMode ? (
              <ArrowBackwardCircleOutline />
            ) : (
              <ArrowBackwardCircleDark />
            )}
          </Show>
        </button>
        <button
          onClick={goNext}
          className="swiper-button-next-2"
          role="button"
          name="Next"
        >
          <Show breakpoint="(min-width: 768px)">
            <CkGreyRightArrow />
          </Show>
          <Show breakpoint="(max-width: 767px)">
            {tabMode ? (
              <ArrowForwardCircleOutline />
            ) : (
              <ArrowForwardCircleDark />
            )}
          </Show>
        </button>
      </div>
    );
  };

  const renderStackContent = () => {
    return (
      <>
        {tabMode ? (
          <>
            <Container
              maxW="container.sm"
              className="fancySectionHeader"
              textAlign={'center'}
              marginBottom={8}
              paddingX={0}
            >
              <div className="headingElement">
                <Heading
                  as="h2"
                  className={cn('titleLg', isDealershipPage && 'titleDealer')}
                  marginBottom={8}
                  paddingLeft={{ base: 6, md: 0 }}
                  paddingRight={{ base: 6, md: 0 }}
                >
                  {sectionTitle ? sectionTitle : 'Discover your new Kia'}
                </Heading>
              </div>
              <Flex
                justifyContent={{ base: 'start', sm: 'center' }}
                flexWrap={'nowrap'}
                overflowX={'auto'}
                paddingLeft={{ base: 6, md: 0 }}
              >
                {filteredBodyTypes.map((grouping, index) => {
                  const isActive = grouping?.body_types.some((value) =>
                    currentSelectedBodyType.includes(value),
                  );

                  return (
                    <ButtonCustom
                      key={index}
                      btnText={grouping.label}
                      role="button"
                      className="btnGreyOutlineDark"
                      moduleClass="filterChip"
                      extraClass={
                        isActive ||
                        (index === 0 && currentSelectedBodyType.length === 0)
                          ? 'active'
                          : ''
                      }
                      onClick={() =>
                        handleBodyTypeSelection(grouping?.body_types)
                      }
                    />
                  );
                })}
              </Flex>
            </Container>
            <div className="fancySectionWrapper">
              <Swiper
                ref={swiperRef}
                className="fancySwiper"
                slidesPerView={3}
                centeredSlides={true}
                loop={true}
                speed={1000}
                parallax={true}
                modules={[Pagination, Navigation, Autoplay]}
                pagination={{
                  el: '.swiper-pagination',
                  type: 'fraction',
                  formatFractionCurrent: function (number) {
                    return number < 10 ? '0' + number : number.toString();
                  },
                }}
                navigation={{
                  nextEl: '.swiper-button-next-2',
                  prevEl: '.swiper-button-prev-2',
                }}
                onSlideChange={(swiper) => {
                  setCurrentPage(swiper.realIndex + 1);
                }}
                onSwiper={(swiper) => {
                  setTotalPages(swiper.slides.length);
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  576: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 180,
                  },
                }}
              >
                {currentCars.map((car: NewCarOverview, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Show breakpoint="(min-width: 576px)">
                        <Link
                          href={`${imageHrefBase}/${slugify(currentCarDetail?.main_title)}`}
                        >
                          <Image
                            src={
                              getImageLink(car?.image_no_background_left_angle)
                                ? `${getImageLink(car?.image_no_background_left_angle)}?key=${imageKeys.desktopKey}`
                                : ''
                            }
                            loader={directusImageLoader}
                            className="fancyImage"
                            alt={car.main_title}
                            width={530}
                            height={270}
                          />
                        </Link>
                      </Show>
                      <Show breakpoint="(max-width: 575px)">
                        <Link
                          href={`${imageHrefBase}/${slugify(currentCarDetail?.main_title)}`}
                        >
                          <Image
                            src={
                              getImageLink(car?.image_no_background_left_angle)
                                ? `${getImageLink(car?.image_no_background_left_angle)}?key=${imageKeys.mobileKey}`
                                : ''
                            }
                            loader={directusImageLoader}
                            className="fancyImage"
                            alt={car.main_title}
                            width={270}
                            height={137}
                          />
                        </Link>
                      </Show>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="fancySwiperControls">
                <div className="swiper-pagination">
                  <span className="current-page">{currentPage}</span>
                  <span className="separator">/</span>
                  <span className="total-pages">{totalPages}</span>
                </div>
                <CustomNavigation />
              </div>
            </div>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              maxW="718px"
              width={'100%'}
              marginLeft={'auto'}
              marginRight={'auto'}
              paddingLeft={{ base: 6, md: 0 }}
              paddingRight={{ base: 6, md: 0 }}
              className="fancyBannerContent"
              flexWrap={'wrap'}
            >
              <Stack className="mobileSwiperControlsWrap">
                <Box className="headingElement">
                  <Text as="b" fontSize={{ base: 'md', md: '2xl' }}>
                    {currentYear}
                  </Text>

                  <Heading as="h2" className="titleLg" marginBottom={4}>
                    {currentCarDetail?.main_title || 'N/A'}
                  </Heading>
                </Box>

                <Box className="tabSwiperControls">
                  <div className="fancySwiperControls">
                    <div className="swiper-pagination">
                      <span className="current-page">{currentPage}</span>

                      <span className="separator">/</span>

                      <span className="total-pages">{totalPages}</span>
                    </div>
                  </div>
                </Box>

                <Box className="mobileSwiperControls">
                  <div className="fancySwiperControls">
                    <div className="swiper-pagination">
                      <span className="current-page">{currentPage}</span>

                      <span className="separator">/</span>

                      <span className="total-pages">{totalPages}</span>
                    </div>

                    <CustomNavigation />
                  </div>
                </Box>
              </Stack>
              <Stack flex="1">
                <HStack
                  direction={['column', 'row']}
                  alignItems={'start'}
                  gap={{ base: 0 }}
                >
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text textTransform={'uppercase'} className="labelText">
                      Starting at
                    </Text>
                    <Text className="lableBigText priceText">
                      <PriceFormat
                        prefix="R"
                        value={currentCarDetail?.price_from || 'N/A'}
                      />
                    </Text>
                    <Text className="labelSmallText">Including VAT</Text>
                  </Box>
                  <Divider
                    orientation="vertical"
                    borderColor={'gray.300'}
                    h={{ base: '80px', sm: '60px', md: '75px' }}
                    marginX={{ base: 2, sm: 4, md: 6 }}
                  />
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text className="labelText" textTransform={'uppercase'}>
                      Financing from
                    </Text>
                    <Text className="lableBigText">
                      <PriceFormat
                        prefix="R"
                        value={currentCarDetail?.price_per_month_from || 'N/A'}
                        suffix="pm*"
                      />
                    </Text>
                    <Text className="labelSmallText">Combined</Text>
                  </Box>
                  <Divider
                    orientation="vertical"
                    borderColor={'gray.300'}
                    h={{ base: '80px', sm: '60px', md: '75px' }}
                    marginX={{ base: 2, sm: 4, md: 6 }}
                  />
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text textTransform={'uppercase'} className="labelText">
                      {`${currentCarDetail?.co2_emissions_heading ?? ''}`}
                    </Text>
                    <Text className="lableBigText">
                      {`${currentCarDetail?.co2_emissions ?? 'N/A'}`}
                    </Text>
                  </Box>
                </HStack>

                <Stack width={'100%'} marginTop={2}>
                  <Box marginTop={6} className="disclaimerPopover">
                    <Popover closeOnBlur={true}>
                      <PopoverTrigger>
                        <button className={'themeLink'}>
                          Disclaimer
                          <ArroRightBlack className={'menuIcon'} />
                        </button>
                      </PopoverTrigger>
                      <Box
                        position={'relative'}
                        className={'disclaimerPopoverContent'}
                      >
                        <PopoverContent borderRadius={0}>
                          <PopoverArrow />
                          <PopoverHeader border={0} mb={3}>
                            <PopoverCloseButton w={8} h={8} />
                          </PopoverHeader>

                          <PopoverBody>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html:
                                  currentCarDetail?.price_disclaimer || 'N/A',
                              }}
                            ></Box>
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    </Popover>
                  </Box>
                </Stack>
              </Stack>
              <Stack
                width={{ base: '100%', md: '190px' }}
                marginTop={{ base: '10', md: '0' }}
                marginLeft={{ base: 'initial', md: 'auto' }}
              >
                {currentCarDetail?.hide_in_build_and_price ? null : (
                  <Box
                    marginBottom={2}
                    maxWidth={{ base: '100%', sm: '45%', md: '100%' }}
                  >
                    <LinkCustom
                      className="btnWhite"
                      moduleClass="ctaBtn"
                      role="link"
                      href={`/build-price/${slugify(currentCarDetail?.main_title)}`}
                    >
                      Build Yours
                    </LinkCustom>
                  </Box>
                )}
              </Stack>
            </Flex>
          </>
        ) : (
          <>
            <Container
              maxW="container.sm"
              className="fancySectionHeader"
              textAlign={'center'}
              marginBottom={8}
              paddingX={0}
            >
              <div className="headingElement">
                <Heading
                  as="h2"
                  className={cn('titleLg', isDealershipPage && 'titleDealer')}
                  marginBottom={8}
                  paddingLeft={{ base: 6, md: 0 }}
                  paddingRight={{ base: 6, md: 0 }}
                >
                  {sectionTitle}
                </Heading>
              </div>
              <Flex
                justifyContent={{ base: 'start', sm: 'center' }}
                flexWrap={'nowrap'}
                overflowX={'auto'}
                paddingLeft={{ base: 6, md: 0 }}
              >
                {body_types?.map((grouping, index) => {
                  const isActive = grouping?.body_types.some((value) =>
                    currentSelectedBodyType.includes(value),
                  );

                  return (
                    <ButtonCustom
                      key={index}
                      btnText={grouping.label}
                      role="button"
                      className="btnGreyOutlineDark"
                      moduleClass="filterChip"
                      extraClass={
                        isActive ||
                        (index === 0 && currentSelectedBodyType.length === 0)
                          ? 'active'
                          : ''
                      }
                      onClick={() =>
                        handleBodyTypeSelection(grouping?.body_types)
                      }
                    />
                  );
                })}
              </Flex>
            </Container>
            <div className="fancySectionWrapper">
              <Swiper
                ref={swiperRef}
                className="fancySwiper"
                slidesPerView={3}
                centeredSlides={true}
                loop={true}
                speed={1000}
                parallax={true}
                modules={[Pagination, Navigation, Autoplay]}
                pagination={{
                  el: '.swiper-pagination',
                  type: 'fraction',
                  formatFractionCurrent: function (number) {
                    return number < 10 ? '0' + number : number.toString();
                  },
                }}
                navigation={{
                  nextEl: '.swiper-button-next-2',
                  prevEl: '.swiper-button-prev-2',
                }}
                onSlideChange={(swiper) => {
                  setCurrentPage(swiper.realIndex + 1);
                }}
                onSwiper={(swiper) => {
                  setTotalPages(swiper.slides.length);
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  576: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 180,
                  },
                }}
              >
                {currentCars.map((car: NewCarOverview, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Show breakpoint="(min-width: 576px)">
                        <Link
                          href={`${imageHrefBase}/${slugify(currentCarDetail?.main_title)}`}
                        >
                          <Image
                            src={
                              getImageLink(car?.image_no_background_left_angle)
                                ? `${getImageLink(car?.image_no_background_left_angle)}?key=${imageKeys.desktopKey}`
                                : ''
                            }
                            loader={directusImageLoader}
                            className="fancyImage"
                            alt={car.main_title}
                            width={530}
                            height={270}
                          />
                        </Link>
                      </Show>
                      <Show breakpoint="(max-width: 575px)">
                        <Link
                          href={`${imageHrefBase}/${slugify(currentCarDetail?.main_title)}`}
                        >
                          <Image
                            src={
                              getImageLink(car?.image_no_background_left_angle)
                                ? `${getImageLink(car?.image_no_background_left_angle)}?key=${imageKeys.mobileKey}`
                                : ''
                            }
                            loader={directusImageLoader}
                            className="fancyImage"
                            alt={car.main_title}
                            width={270}
                            height={137}
                          />
                        </Link>
                      </Show>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="fancySwiperControls">
                <div className="swiper-pagination">
                  <span className="current-page">{currentPage}</span>
                  <span className="separator">/</span>
                  <span className="total-pages">{totalPages}</span>
                </div>
                <CustomNavigation />
              </div>
            </div>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              maxW="718px"
              width={'100%'}
              marginLeft={'auto'}
              marginRight={'auto'}
              paddingLeft={{ base: 6, md: 0 }}
              paddingRight={{ base: 6, md: 0 }}
              className="fancyBannerContent"
            >
              <Stack>
                <Box className="mobileSwiperControlsWrap">
                  <Box className="headingElement">
                    <Text as="b" fontSize={{ base: 'sm', sm: '2xl' }}>
                      {currentYear}
                    </Text>
                    <Heading as="h2" className="titleLg" marginBottom={4}>
                      {currentCarDetail?.main_title || 'N/A'}
                    </Heading>
                  </Box>
                  <Box className="mobileSwiperControls">
                    <div className="fancySwiperControls">
                      <div className="swiper-pagination">
                        <span className="current-page">{currentPage}</span>
                        <span className="separator">/</span>
                        <span className="total-pages">{totalPages}</span>
                      </div>
                      <CustomNavigation />
                    </div>
                  </Box>
                </Box>
                <HStack
                  direction={['column', 'row']}
                  alignItems={'start'}
                  gap={{ base: 0 }}
                >
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text textTransform={'uppercase'} className="labelText">
                      Starting at
                    </Text>
                    <Text className="lableBigText priceText">
                      <PriceFormat
                        prefix="R"
                        value={currentCarDetail?.price_from || 'N/A'}
                      />
                    </Text>
                    <Text className="labelSmallText">Including VAT</Text>
                  </Box>
                  <Divider
                    orientation="vertical"
                    borderColor={'gray.300'}
                    h={{ base: '80px', sm: '60px', md: '75px' }}
                    marginX={{ base: 2, sm: 4, md: 6 }}
                  />
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text className="labelText" textTransform={'uppercase'}>
                      fuel consumption
                    </Text>
                    <Text className="lableBigText">
                      {` ${
                        currentCarDetail?.images_fuel_consumption ?? 'N/A'
                      }L/100km`}
                    </Text>
                    <Text className="labelSmallText">Combined</Text>
                  </Box>
                  <Divider
                    orientation="vertical"
                    borderColor={'gray.300'}
                    h={{ base: '80px', sm: '60px', md: '75px' }}
                    marginX={{ base: 2, sm: 4, md: 6 }}
                  />
                  <Box maxWidth={'190px'} width={'100%'}>
                    <Text textTransform={'uppercase'} className="labelText">
                      {`${currentCarDetail?.co2_emissions_heading ?? ''}`}
                    </Text>
                    <Text className="lableBigText">
                      {`${currentCarDetail?.co2_emissions ?? 'N/A'}`}
                    </Text>
                  </Box>
                </HStack>
              </Stack>
              <Stack
                minWidth="190px"
                alignSelf={{ md: 'center' }}
                marginTop={{ base: '10', md: '20' }}
                marginLeft={{ base: 'initial', md: 'auto' }}
              >
                {currentCarDetail?.hide_in_build_and_price ? null : (
                  <Box
                    marginBottom={2}
                    maxWidth={{ base: '100%', sm: '45%', md: '100%' }}
                  >
                    <LinkCustom
                      className="btnOutline"
                      moduleClass="ctaBtn"
                      href={`/build-price/${slugify(currentCarDetail?.main_title)}`}
                    >
                      Build Yours
                    </LinkCustom>
                  </Box>
                )}
                <Box maxWidth={{ base: '100%', sm: '45%', md: '100%' }}>
                  <LinkCustom
                    className=""
                    moduleClass="ctaBtn"
                    role="link"
                    href={`${imageHrefBase}/${slugify(currentCarDetail?.main_title)}`}
                  >
                    {`Explore ${currentCarDetail?.main_title}`}
                  </LinkCustom>
                </Box>
              </Stack>
            </Flex>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Show breakpoint="(min-width: 576px)">
        <Stack
          className={cn(
            'fancySectionWrap',
            tabMode && 'tabSliderSection',
            isDealershipPage && 'noGradient',
          )}
        >
          <Box
            className="fancyBannerBg"
            style={{
              backgroundImage: new_cars_background
                ? `url(${new_cars_background}?key=${backgroundImageKeys.desktopKey})`
                : '',
            }}
          />
          {renderStackContent()}
        </Stack>
      </Show>
      <Show breakpoint="(max-width: 575px)">
        <Stack
          className={cn(
            'fancySectionWrap',
            tabMode && 'tabSliderSection',
            isDealershipPage && 'noGradient',
          )}
        >
          <Box
            className="fancyBannerBg"
            style={{
              backgroundImage: new_cars_background
                ? `url(${new_cars_background}?key=${backgroundImageKeys.mobileKey})`
                : '',
            }}
          />
          {renderStackContent()}
        </Stack>
      </Show>
    </>
  );
};

export default FancyCarousel;
